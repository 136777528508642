<template>
         <div class="flex">
                 <div class="InfoList">
                        <info-card title="İşletme" :number="NumberOfBusiness" icon="store"/>
                        <info-card title="HotSpot Alanı" :number="NumberOfHotSpots" icon="settings_input_antenna"/>
                        <info-card title="Kayıtlı Müşteri" :number="TotalHotSpotUser" icon="people_alt"/>
                        <info-card title="Yönetici" :number="TotalAdmin" icon="people_alt"/>
                 </div>
                 <div class="ma-3"></div>
                 <div class="InfoList">
                        <program-list />
                        <last-insert-business />
                        <notices />
                 </div>
        </div>
</template>

<script>
    import InfoCard from "../../components/dashboard/InfoCard";
    import ProgramList from "../../components/dashboard/ProgramList";
    import LastInsertBusiness from "../../components/dashboard/LastInsertBusiness";
    import Notices from "../../components/dashboard/Notices";
    export default {
        name: "Dashboard",
            components: {Notices, LastInsertBusiness, ProgramList, InfoCard},
            computed:{
                NumberOfHotSpots(){
                    return (this.$store.state.hotspot.data.length + 1);
                },
                NumberOfBusiness(){
                    return (this.$store.state.business.data.length+ 1);
                },
                TotalHotSpotUser(){
                    return this.$store.state.business.TotalHotSpotUser;
                },
                TotalAdmin(){
                    return this.$store.state.business.TotalAdmin;
                }
            },
            mounted() {
                if(this.$store.state.isLogin) {
                        this.$store.state.PageTitle = "Dashboard"
                }
                if(this.$store.state.hotspot.data.length<1){
                    this.$store.dispatch("HotSpotListUpdate")
                }
        }

    }
</script>

<style scoped>
        .InfoList {
                display: flex;
                flex: 1;
                flex-direction: row;
                flex-wrap: wrap;
        }
</style>