<template>
    <v-card class="programList">
        <v-toolbar dark dense :style="{background: $vuetify.theme.themes[theme].background,color:$vuetify.theme.themes[theme].color}">
            <v-toolbar-title>Son Eklenenler</v-toolbar-title>
            <v-spacer></v-spacer>

        </v-toolbar>

        <v-list dense >
            <div v-for="chat in liste"
                 :key="chat.P_Id"
            >
                <v-list-item @click="dialog=true">
                    <v-list-item-content>
                        <v-list-item-title v-text="chat.P_Name"></v-list-item-title>
                    </v-list-item-content>

                </v-list-item>
                <v-divider ></v-divider>
            </div>
        </v-list>


    </v-card>
</template>

<script>
    export default {
        name: "LastInsertBusiness",
        data(){
            return {
                dialog:false,
            }
        },
        computed: {
            theme(){
                return (this.$vuetify.theme.dark) ? 'dark' : 'light'
            },
          liste(){
              return this.$store.state.business.last20;
            }
        },
        created() {
            this.$store.dispatch("Last20List");
            this.$store.dispatch("DashboardData");
        }
    }
</script>

<style scoped>
    .programList {
        box-sizing: border-box;
        max-width: 100%;
        min-width: 21%;
        margin-right:1%;
        margin-bottom: 1%;
        flex-basis: 300px;
        flex-grow: 1;
    }
    .v-list{
        height:400px;/* or any height you want */
        overflow-y:auto
    }
</style>