<template>
    <v-card outlined class="infoCard" elevation="7" @click="dialog=true">
        <v-list-item three-line>
            <v-list-item-avatar size="50">
                <v-icon size="45" color="primary">{{ icon }}</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title class="headline mb-1">{{ number }}</v-list-item-title>
                <v-list-item-subtitle>{{ title }}</v-list-item-subtitle>
            </v-list-item-content>

        </v-list-item>
    </v-card>
</template>

<script>
    export default {
        name: "infoCard",
        props:['title','number','icon'],
    }
</script>

<style scoped>
    .infoCard {
        box-sizing: border-box;
        max-width: 100%;
        min-width: 21%;
        margin-right:1%;
        margin-bottom: 1%;
        flex-basis: 250px;
        flex-grow: 1;

    }

</style>