<template>
    <v-card class="programList">
        <v-toolbar dark dense :style="{background: $vuetify.theme.themes[theme].background,color:$vuetify.theme.themes[theme].color}">
            <v-toolbar-title>Duyurular</v-toolbar-title>
            <v-spacer></v-spacer>

        </v-toolbar>

        <v-list dense>
            <div v-for="chat in recent"
                 :key="chat.title"
            >
                <v-list-item @click="dialog=true">
                    <v-list-item-content>
                        <v-list-item-title v-text="chat.title"></v-list-item-title>
                    </v-list-item-content>

                </v-list-item>
                <v-divider ></v-divider>
            </div>
        </v-list>


    </v-card>
</template>

<script>
    export default {
        name: "Notices",
        data(){
            return {
                dialog:false,
                recent: [
                    {
                        active: true,
                        avatar: '/assets/images/software/moowifi.png',
                        title: 'MooWifi WEB',
                    },
                    {
                        avatar: '/assets/images/software/sistemotel.png',
                        title: 'Sistem Otel',
                    },
                    {
                        active: true,
                        avatar: '/assets/images/software/amonra.png',
                        title: 'Amonra',
                    },
                    {
                        avatar: '/assets/images/software/elektra.png',
                        title: 'Elektra',
                    },
                    {
                        avatar: '/assets/images/software/opera.png',
                        title: 'Opera',
                    },
                    {
                        avatar: '/assets/images/software/hms.png',
                        title: 'HMS',
                    },
                ],
            }
        },
        computed: {
            theme() {
                return (this.$vuetify.theme.dark) ? 'dark' : 'light'
            },
        }
    }
</script>

<style scoped>
    .programList {
        box-sizing: border-box;
        max-width: 100%;
        min-width: 21%;
        margin-right:1%;
        margin-bottom: 1%;
        flex-basis: 300px;
        flex-grow: 1;
    }

    .v-list{
        height:400px;/* or any height you want */
        overflow-y:auto
    }
</style>